import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export const usePageTitle = (): void => {
  const { t } = useI18n()

  const route = useRoute()

  const getTitle = (): string => {
    const matches = route.matched

    for (let i = matches.length - 1; i > 0; i--) {
      const match = matches[i]
      if (match?.meta?.title) return match.meta.title
    }

    return 'fallback'
  }

  watch(route, () => {
    document.title = `Owner.com | ${t(`core.pages.${getTitle()}.title`)}`
  })
}
