import * as Sentry from '@sentry/vue'
import { type App } from 'vue'

const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const MODE = import.meta.env.MODE

const APP_VERSION = import.meta.env.APP_VERSION

export const initializeSentry = (app: App): void => {
  Sentry.init({
    app,
    attachStacktrace: true,
    dsn: VITE_SENTRY_DSN,
    environment: MODE,
    integrations: [
      // Third-party Error filter
      // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/filtering/#using-thirdpartyerrorfilterintegration
      Sentry.thirdPartyErrorFilterIntegration({
        behaviour: 'drop-error-if-contains-third-party-frames',
        filterKeys: ['owner-hermes-web'],
      }),
      // Browser tracing for performance
      // https://docs.sentry.io/platforms/javascript/guides/vue/tracing/
      Sentry.browserTracingIntegration(),
    ],

    release: `${MODE}@${APP_VERSION}`,

    // Sets 10% of transactions to be reported
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/sampling/#setting-a-uniform-sample-rate
    tracesSampleRate: 0.1,

    // Tracks all components
    // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/#trackcomponents
    trackComponents: true,
  })
}
