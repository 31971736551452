import { isAfter, parseISO } from 'date-fns'
import { onBeforeMount, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useMappedAction, useMappedGetter } from '@/store'

export const useSessionManager = (): void => {
  let interval: ReturnType<typeof setInterval> | null = null

  const router = useRouter()

  const expiresAt = useMappedGetter<string>('session/expiresAt')
  const isAuthenticated = useMappedGetter<boolean>('session/isAuthenticated')

  const logout = useMappedAction('session/logout')

  watch(isAuthenticated, async (isAuthenticated) => {
    if (!isAuthenticated) {
      await router.push({ name: 'auth.login' })
    }
  })

  onBeforeMount(() => {
    if (interval) clearInterval(interval)

    interval = setInterval(async () => {
      const date = new Date()

      if (expiresAt.value && isAfter(date, parseISO(expiresAt.value))) {
        await logout()
      }
    }, 30 * 1000)
  })

  onBeforeUnmount(() => {
    if (interval) clearInterval(interval)
  })
}
