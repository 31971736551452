import { MotionPlugin } from '@vueuse/motion'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from './App/App.vue'
import AnalyticsPlugin from './plugins/analytics/plugin'
import FeatureFlagsPlugin from './plugins/featureFlags'
import i18n from './plugins/i18n'
import { initializeSentry } from './plugins/sentry'
import { router } from './router/router'
import store from './store'
import './styles/main.scss'

const MODE = import.meta.env.MODE

const pinia = createPinia()

const app = createApp(App)

if (MODE === 'production' || MODE === 'staging') {
  initializeSentry(app)
}

app.config.performance = import.meta.env.MODE !== 'production'

app.use(i18n)
app.use(pinia)
app.use(router)
app.use(store)
app.use(MotionPlugin)
app.use(FeatureFlagsPlugin)
app.use(AnalyticsPlugin)

app.mount('#app')
