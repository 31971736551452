import { type Plugin } from 'vue'

import analytics from './analytics'

const AnalyticsPlugin: Plugin = {
  install: function (app) {
    app.config.globalProperties.$analytics = analytics
  },
}

export default AnalyticsPlugin
