import { watch } from 'vue'

import { useMappedGetter } from '@/store'

export const useLocaleHandler = (): void => {
  const locale = useMappedGetter<string>('app/locale')

  // eslint-disable-next-line @typescript-eslint/unbound-method
  watch(locale, window.location.reload)
}
