<template>
  <div class="toast-manager">
    <Toast
      v-if="show"
      v-motion-slide-bottom
      :message="text"
      :variant="mode"
      :timeout="timeout"
    />
  </div>
</template>

<script>
import noop from 'lodash/noop'

import bus from '@/mixins/bus'

import Toast from './Toast.vue'

const defaultToastSettings = {
  callback: noop,
  mode: 'info',
  show: false,
  text: '',
  timeout: 5000,
}

export default {
  name: 'ToastManager',
  components: { Toast },
  mixins: [bus],
  data() {
    return {
      ...defaultToastSettings,
      hideTimeout: undefined,
    }
  },
  mounted() {
    this.$bus.on('root-notification', this.showToast)
  },
  methods: {
    handleClose() {
      this.show = false
      this.callback()

      clearTimeout(this.hideTimeout)
    },

    /**
     * Exposes a global notification toast `root-notification` global event
     * @param {object} params a message or a custom configuration object
     * @param {boolean} params.mode "success", "error", "warning", "info" - default "info"
     * @param {string} params.text message to display
     * @param {Function} params.callback callback for when the button is clicked
     * @public
     */
    showToast(params) {
      if (typeof params === 'string') params = { text: params }

      Object.assign(this, {
        ...defaultToastSettings,
        ...params,
        show: true,
      })

      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
      }

      this.hideTimeout = setTimeout(this.handleClose, this.timeout)
    },
  },
}
</script>

<style lang="scss" scoped>
.toast-manager {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 500;
}
</style>
